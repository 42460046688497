export const userAccess = {
  computed: {
    entities () {
      return ['party']
    },
    adminEntities () {
      return [...this.entities, 'key', 'key_value', 'relationship_type']
    },
    adminRoles () {
      return ['jbg-admin', 'pce-admin', 'customer.admin', 'client-admin', 'iPro-Admin-Customer']
    },
    user () {
      return this.$auth.user
    },
    tenant () {
      return this.$auth.tenant
    },
    userRoles () {
      return this.$auth.userRoles || []
    },
    userPermissions () {
      return this.$auth.permissions || []
    },
    userRelatedParties () {
      return this.$store.getters.userRelatedParties
    },
    userRelatedPartyIds () {
      if (this.userRelatedParties.length > 0) {
        return this.userRelatedParties.map(party => party.id)
      }
      return []
    },
    hasPortalAccess () {
      return !this.hasScope('customer:no_access') && this.hasReadAccess
    },
    hasReadAccess () {
      const readScopes = this.entities.map(entity => `${entity}:read`)
      return readScopes.every(scope => this.userPermissions.includes(scope))
    },
    hasWriteAccess () {
      return this.writePermissions.every(scope => this.userPermissions.includes(scope))
    },
    writePermissions () {
      const actions = ['create', 'read', 'update']
      return this.$auth.buildScopes(this.entities, actions)
    },
    isIproAdmin() {
      return this.userRoles.includes('iPro-Admin-Customer')
    },
    isAdmin () {
      return this.adminRoles.some(role => this.userRoles.includes(role))
    },
    adminPermissions () {
      return this.$auth.buildScopes(this.adminEntities)
    },
    limitAccessByUserParties () {
      return this.userPermissions.includes('customer:related_parties')
    }
  },
  methods: {
    hasScope (scope) {
      return this.userPermissions.includes(scope)
    }
  }
}