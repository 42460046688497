import Vue from 'vue'
import createAuth0Client from '@auth0/auth0-spa-js'

let instance

export const getInstance = () => instance

export const useAuth0 = ({
  onRedirectCallback = () =>
    window.history.replaceState({}, document.title, window.location.pathname),
  redirectUri = window.location.origin,
  namespace = 'https://iprosystems.com',
  ...options
}) => {

  if (instance) return instance

  instance = new Vue({
    data () {
      return {
        isLoading: true,
        isAuthenticated: false,
        user: {},
        idToken: {},
        auth0Client: null,
        error: null,
        tenantTitleConfig: {'awg': 'AIM', 'pricechopper':'PCE', 'alliance-retail-group':'ARG'},
        tenantPrefixConfig: { 'pricechopper': 'pce', 'alliance-retail-group': 'arg' }

      }
    },
    async created () {
      this.auth0Client = await createAuth0Client({
        ...options,
        domain: options.domain,
        client_id: options.clientId,
        audience: options.audience,
        redirect_uri: redirectUri,
        cacheLocation: 'localstorage'
      })

      try {
        if (
          window.location.search.includes('code=') &&
          window.location.search.includes('state=')
        ) {
          const { appState } = await this.auth0Client.handleRedirectCallback()
          onRedirectCallback(appState)
        }
      } catch (e) {
        this.error = e
      } finally {
        this.isAuthenticated = await this.auth0Client.isAuthenticated()
        this.user = await this.auth0Client.getUser()
        this.idToken = await this.auth0Client.getIdTokenClaims()
        this.isLoading = false
      }
    },
    computed: {
      userRoles () {
        return this.idToken ? this.idToken[`${namespace}/roles`] : []
      },
      tenant () {
        return this.idToken ? this.idToken[`${namespace}/tenant`] : ''
      },
      permissions () {
        return this.idToken ? this.idToken[`${namespace}/permissions`] : []
      },
      tenantTitle () {
        if (this.isAuthenticated && this.tenant !== 'iprosystems') {
          return this.tenantTitleConfig[this.tenant] || `${this.tenant.toUpperCase()}`
        }
        return null
      },
      tenantURLPrefix () {
        if (this.isAuthenticated  && this.tenant !== 'iprosystems') {
          return this.tenantPrefixConfig[this.tenant] || this.tenant
        }
        return null
      }
    },
    methods: {
      async handleRedirectCallback () {
        this.isLoading = true
        try {
          await this.auth0Client.handleRedirectCallback()
          this.user = await this.auth0Client.getUser()
          this.idToken = await this.auth0Client.getIdTokenClaims()
          this.isAuthenticated = true
        } catch (e) {
          this.error = e
        } finally {
          this.isLoading = false
        }
      },
      loginWithRedirect (options) {
        return this.auth0Client.loginWithRedirect(options)
      },
      getIdTokenClaims (options) {
        return this.auth0Client.getIdTokenClaims(options)
      },
      getTokenSilently (options) {
        return this.auth0Client.getTokenSilently(options)
      },
      logout (options) {
        return this.auth0Client.logout(options)
      },
      buildScopes (entities, actions = null) {
        if (!actions) actions = ['create', 'read', 'update', 'delete']
        return entities.flatMap(entity => {
          return actions.map(action => `${entity}:${action}`)
        })
      },
      checkScopes (scopes) {
        return scopes.every(scope => {
          return this.permissions.includes(scope)
        })
      }
    }
  })

  return instance
}

export const Auth0Plugin = {
  install (Vue, options) {
    Vue.prototype.$auth = useAuth0(options)
  }
}
